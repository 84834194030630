import { APICore } from './apiCore';

const api = new APICore();

function addInv(params: { name: string, }) {
  
    const baseUrl = '/add-inv';
    return api.create(`${baseUrl}`, params);
}
function addInvSale(params: { entrydate:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,phonecode:string,invoicedetdata:Object }) {
  
    const baseUrl = '/add-sale-invoice';
    return api.create(`${baseUrl}`, params);
}
function updInvSale(params: { id:string,entrydate:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,phonecode:string,invoicedetdata:Object }) {
  
    const baseUrl = `/update-salesinvoice/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addPOSSale(params) {
    const baseUrl = '/add-pos-invoice';
    return api.create(`${baseUrl}`, params);
}
function updPOSSale(params) {
    const baseUrl = `/update-posinvoice/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addInvReturn_API(params: { entrydate:string,invoicenumber:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,phonecode:string,invoicedetdata:Object }) {
  
    const baseUrl = '/add-invoice-return';
    return api.create(`${baseUrl}`, params);
}
function updInvReturn_API(params: { id:string,entrydate:string,invoicenumber:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,shipmentno:string,carrierid:string,totalDiscount:string,totalprice:string,cashreceived:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,phonecode:string,invoicedetdata:Object }) {
  
    const baseUrl = `/update-invoicereturn/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addInvGrid(params: { data:Object }) {
  
    const baseUrl = '/add-inv';
    return api.create(`${baseUrl}`, params);
}
function add_payReceipt(params: { customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string }) {
  
    const baseUrl = '/add-receipt';
    return api.create(`${baseUrl}`, params);
}
function upd_payReceipt(params: { id:string,customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string ,reference:string}) {
  
    const baseUrl = '/update-receipt/'+params.id;
    return api.update(`${baseUrl}`, params);
}

export {addPOSSale,
  updPOSSale,addInvReturn_API,
updInvReturn_API, addInv,addInvGrid ,addInvSale,updInvSale,add_payReceipt,upd_payReceipt};
