// constants
import { PurchaseActionTypes } from './constants';

export type PurchaseActionType = {
    type:
        | PurchaseActionTypes.API_RESPONSE_SUCCESS
        | PurchaseActionTypes.API_RESPONSE_ERROR
        | PurchaseActionTypes.RESET
        | PurchaseActionTypes.ADD_Purchase
        | PurchaseActionTypes.ADD_PURCHASEINV
        | PurchaseActionTypes.UPD_PURCHASEINV
        | PurchaseActionTypes.UPD_PURCHASE_RETURN
        | PurchaseActionTypes.ADD_PURCHASE_RETURN
        | PurchaseActionTypes.ADD_PURCHASEGRID;
    payload: {} | string;
};

type InvoiceData = {
    name: string;
    };

// common success
export const purchaseApiResponseSuccess = (actionType: string, data: InvoiceData | {}): PurchaseActionType => ({
    type: PurchaseActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const purchaseApiResponseError = (actionType: string, error: string): PurchaseActionType => ({
    type: PurchaseActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addPurchase = (name:string): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_Purchase,
    payload: {name, },
});

export const addPurchaseInv = (entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,purchasedetdata:Object,invoicetermsid:string,shipped_via:string,trackNumber:string,tax:string,taxamount:string): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_PURCHASEINV,
    payload: {entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,AdvancePayment,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,purchasedetdata,invoicetermsid,shipped_via,trackNumber,tax,taxamount },
});
export const updPurchaseInv = (id:string,entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,purchasedetdata:Object,invoicetermsid:string,shipped_via:string,trackNumber:string,tax:string,taxamount:string ): PurchaseActionType => ({
    type: PurchaseActionTypes.UPD_PURCHASEINV,
    payload: {id,entrydate,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,AdvancePayment,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,purchasedetdata,invoicetermsid,shipped_via,trackNumber,tax,taxamount },
});
export const addPurReturn = (entrydate:string,purchasenumber:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,purchasedetdata:Object,invoicetermsid:string,shipped_via:string,trackNumber:string,tax:string,taxamount:string): PurchaseActionType=> ({
   type: PurchaseActionTypes.ADD_PURCHASE_RETURN,
   payload: {entrydate,purchasenumber,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,AdvancePayment,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,purchasedetdata,invoicetermsid,shipped_via,trackNumber,tax,taxamount},
});
export const editPurReturn = (id:string,entrydate:string,purchasenumber:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,purchasedetdata:Object,invoicetermsid:string,shipped_via:string,trackNumber:string): PurchaseActionType=> ({
    type: PurchaseActionTypes.UPD_PURCHASE_RETURN,
    payload: {id,entrydate,purchasenumber,supplierid,transtype,reference,notes,freight,totalDiscount,totalprice,AdvancePayment,subtotal,expense,netamount,custfreight,custexpense,name,phone,address,purchasedetdata,invoicetermsid,shipped_via,trackNumber, },
});
export const addPurchaseGrid = (data:Object): PurchaseActionType => ({
    type: PurchaseActionTypes.ADD_PURCHASEGRID,
    payload: {data },
});
export const supplier_payment = (supplierid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string) => ({
    type: PurchaseActionTypes.SUPPLIER_PAYMENT,
    payload: {supplierid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference },
});
export const upd_supplier_payment = (id:string,supplierid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string) => ({
    type: PurchaseActionTypes.UPD_SUPPLIER_PAYMENT,
    payload: {id,supplierid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference },
});


export const resetPurchase = (): PurchaseActionType => ({
    type: PurchaseActionTypes.RESET,
    payload: {},
});
